var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"slide-card",attrs:{"hover":"","rounded":"lg"}},[_c('v-img',{attrs:{"contain":"","loading":"lazy","src":_vm.landscape || '/assets/img/slides/placeholder-slide.svg',"alt":"","aspect-ratio":16 / 9},on:{"click":function($event){return _vm.handleCardClick('thumbnail')}}},[_c('div',{staticClass:"image-overlay-content"},[_c('div',{staticClass:"badge-container"},[(_vm.showBadge)?_c('v-badge',{style:({ zIndex: 4 }),attrs:{"content":_vm.$t('common.newContent'),"overlap":"","tile":"","offset-x":"0","offset-y":"0"}}):_vm._e()],1),(_vm.slideMetaData.isTrending)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"bpe-trending--auto",attrs:{"src":"/assets/img/trending.svg","alt":"","width":"34px","height":"34px"}},'v-img',attrs,false),on))]}}],null,false,412617753)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowSlideCard.trending'))+" ")])]):_vm._e(),(_vm.slideMetaData.isImageReplaced || _vm.slideMetaData.isIconReplaced)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"bpe-replaced-download--auto bpe-download--auto",attrs:{"src":"/assets/img/already-downloaded-image-replaced.svg","alt":"","width":"34px","height":"34px"}},'v-img',attrs,false),on))]}}],null,false,747561592)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowSlideCard.downloaded'))+" ")])]):(_vm.slideMetaData.isDownloaded)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"bpe-download--auto",attrs:{"src":"/assets/img/already-downloaded.svg","alt":"","width":"34px","height":"34px"}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowSlideCard.downloaded'))+" ")])]):_vm._e()],1)]),_c('v-card-actions',[_c('div',{staticClass:"action-items"},[_c('v-row',[_c('v-col',{staticClass:"slide-title"},[_c('span',[_vm._v(_vm._s(_vm.slide.title))])]),_c('v-col',{staticClass:"d-flex col-auto"},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.toggleFav === true ? null : _vm.handleFavourite()}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.toggleFav === true}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.slideMetaData.isFavourite ? 'mdi-heart' : 'mdi-heart-outline')+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.slideMetaData.isFavourite ? _vm.$t('bestPracticesGuide.knowSlideCard.removeFavorites') : _vm.$t('bestPracticesGuide.knowSlideCard.addFavorites'))+" ")])]),(_vm.isLocalDownloading)?_c('v-progress-circular',{style:({ margin: '0px 8px 0px 8px' }),attrs:{"indeterminate":"","size":20,"color":"primary"}}):_c('Download',{attrs:{"showIconButton":"","mode":'icon',"file-name":_vm.slide.filename || '',"item":_vm.slide,"log-download":_vm.logDownload,"count-first-time":1,"on-download":_vm.makeLocalDownload,"origin":"bpe-slide","limitsKey":"bpe-slide","isDisabled":_vm.isDownloadBlocked,"downloadBlocked":_vm.isDownloadBlocked}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.copyBPESlideLink()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-link-variant ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(("" + (_vm.showCopied ? _vm.$t('bestPracticesGuide.knowSlideCard.linkCopied') : _vm.$t('navbarActions.shareFreeTrial.copyLink'))))+" ")])])],1)],1)],1),_c('v-row',{staticClass:"sub-action-item"},[_c('v-col',[_c('div',{staticClass:"author-label"}),_c('div',{staticClass:"typeAndDate"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"descripion"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.description'))+": "+_vm._s(_vm.getDescription(_vm.slide.title) || _vm.getDescription(_vm.slide.asset.title))+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.getDescription(_vm.slide.title) || _vm.getDescription(_vm.slide.asset.title))+" ")])])],1),_c('div',{staticClass:"viewAndtype"},[_c('div',{staticClass:"views"},[_vm._v(" "+_vm._s(("" + (_vm.slideMetaData.viewCount > 0 ? ((_vm.slideMetaData.viewCount) + " " + (_vm.slideMetaData.viewCount === 1 ? _vm.$t('bestPracticesGuide.knowSlideCard.view') : _vm.$t('bestPracticesGuide.knowSlideCard.views'))) : _vm.$t('bestPracticesGuide.knowSlideCard.noViews'))))+" ")]),(_vm.slide.related_words && _vm.slide.related_words.length > 0)?[_c('div',[_vm._v("  |  ")]),_c('div',{staticClass:"also-relevant-to"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"descripion"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowContainer.alsoReleventTo'))+" "+_vm._s(_vm.slide.related_words)+" ")])]}}],null,false,1969908625)},[_c('span',[_vm._v(" "+_vm._s(_vm.slide.related_words)+" ")])])],1)]:_vm._e()],2),_c('div',{staticClass:"author-label"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowSlideCard.source'))+": "+_vm._s(_vm.slide.prefs.source === 'prezent' ? _vm.$t('common.prezent') : _vm.sourceDisplayNameRef && _vm.sourceDisplayNameRef[_vm.slide.prefs.source] ? _vm.sourceDisplayNameRef[_vm.slide.prefs.source] : _vm.slide.prefs.source)+" ")])])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }