<template>
  <div class="knowDecksContainer">
    <div class="deckListLoader" v-if="loading || isSearching">
      <Loading />
    </div>
    <div v-else>
      <template v-if="decksLoadedOnPage.length">
        <v-item-group active-class="primary" class="text-center">
          <v-container fluid>
            <v-row>
              <v-col
                v-for="(prezentation, i) in decksLoadedOnPage"
                :key="`${prezentation.id}__${i}`"
                class="col-xs-12 col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4"
                :style="[
                  $vuetify.breakpoint.xs ||
                  $vuetify.breakpoint.sm ||
                  $vuetify.breakpoint.md
                    ? { 'min-width': '300px' }
                    : {},
                ]"
              >
                <v-item>
                  <KnowDeckCards
                    :prezentation="prezentation"
                    :origin="origin"
                    :isFirstcard="i === 0 ? true : false"
                    :handleDetailClick="handleDetailClick"
                    :trendingRange="getBPLDeckTrendingRange"
                    :searchText="searchText"
                  />
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
        <div class="decks-loadmore">
          <v-btn
            rounded
            outlined
            color="#21a7e0"
            height="32"
            min-width="157px"
            :disabled="!getIsLoadMore"
            @click="handleLoadMoreDecks"
          >
            {{
              `${
                !getIsLoadMore
                  ? $t('bestPracticesGuide.knowContainer.noMoreResults')
                  : $t('bestPracticesGuide.knowContainer.seeMoreResults')
              }`
            }}
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div class="no-decks">
          <img
            src="/assets/img/know/no-results-found-know-decks.svg"
            width="400px"
          />
          <h2>{{ $t('bestPracticesGuide.knowContainer.noResultsFound') }}</h2>
          <p>{{ $t('search.dontLoseHeart') }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import EventBus from '../../common/event-bus';
import {
  getFingerPrintAdditionalData,
  getPrezentationSearch,
} from '../../../utils/api-helper';
import KnowDeckCards from './KnowDeckCards.vue';
import { trackKnowEvents } from '../../common/Analytics/KnowEvents';
import {
  TD_PLACEMENT,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_SECTION_CHANGED,
  TD_FROM_SNACK_BAR_DECKS,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import Loading from '../../common/Loading.vue';
import {
  getTypeSenseAPIKey,
  TypeSenseServer,
} from '../../../pages/components/TypeSenseInit';

export default {
  name: 'KnowDecks',
  data() {
    return {
      loading: false,
      decksLoadedOnPage: [],
      totalDecks: 0,
      tsKey: '',
    };
  },
  components: {
    KnowDeckCards,
    Loading,
  },
  props: {
    origin: {
      type: String,
      default: 'landing_page',
    },
    allDecksFromHybridSearch: {
      type: Array,
      default: () => [],
    },
    decksFromHybridSerach: {
      type: Array,
      default: () => [],
    },
    isKnowDecksLoaded: {
      type: Function,
      default: () => {},
    },
    doFilterChange: {
      type: Function,
      default: () => {},
    },
    handleSearchChange: {
      type: Function,
      default: () => {},
    },
    handleLoadMore: {
      type: Function,
      default: () => {},
    },
    searchText: {
      type: String,
      default: '',
    },
    handleDetailClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentTheme']),
    ...mapGetters('users', ['isFilterByBPEFunctionEnabled']),
    ...mapGetters('bestPracticesExamples', [
      'getDecksLoaded',
      'getBPEDeckLandingSearchText',
      'getDeckPage',
      'getIsLoadMore',
    ]),
    advancedItems: {
      get() {
        return this.$store.state.advancedFilterStore.knowDeck.advancedItems;
      },
      set() {},
    },
    ...mapGetters('commonDownloads', [
      'getNavFromDownloadSnackbarBPDck',
      'getNavFromAddToLibOrFavSnackbar',
    ]),

    // function to find top 10 percent trending range for bpl decks
    getBPLDeckTrendingRange() {
      // Check if getDecksLoaded is a valid array
      if (this.getDecksLoaded.length === 0) {
        return [];
      }

      // Getting 10 percent of the size from the total size
      const trendingSize = Math.ceil(this.getDecksLoaded.length / 10);

      // Sorting decks based on view count
      const sortedDecksOnViewCount = [...this.getDecksLoaded].sort((a, b) => {
        const viewCountA = Number(a?.viewCount) || 0;
        const viewCountB = Number(b?.viewCount) || 0;
        return viewCountB - viewCountA;
      });

      // Getting top 10 percent view counts
      const trendingRange = sortedDecksOnViewCount
        .slice(0, trendingSize)
        .map((deck) => Number(deck?.viewCount) || 0);

      return trendingRange;
    },
    isSearching: {
      get() {
        return this.$store.state.advancedFilterStore.knowDeck.isSearching;
      },
      set() {},
    },
  },
  watch: {
    // deep: true,
    // immediate: true,
    getDecksLoaded: {
      handler(val) {
        if (this.origin === 'hybrid_search') {
          this.decksLoadedOnPage = val.slice(0, 6);
        } else {
          this.decksLoadedOnPage = val;
        }
      },
      // deep: true,
      // immediate: true,

      getNavFromDownloadSnackbarBPDck(val) {
        if (
          val.navigated &&
          this.$route.fullPath === '/home/best-practice-library'
        ) {
          if (val.type === 'bpDecks') {
            this.handleNavFromDownloadSnackbarForDecks();
            this.setNavFromDownloadSnackbarBPDck({
              navigated: false,
              type: '',
            });
          }
        }
      },
    },

    // getNavFromDownloadSnackbarBPDck(val) {
    //   if (
    //     val.navigated &&
    //     this.$route.fullPath === '/home/best-practice-library'
    //   ) {
    //     if (val.type === 'bpDecks') {
    //       this.handleNavFromDownloadSnackbarForDecks();
    //       this.setNavFromDownloadSnackbarBPDck({
    //         navigated: false,
    //         type: '',
    //       });
    //     }
    //   }
    // },

    // getNavFromAddToLibOrFavSnackbar(val) {
    //   if (
    //     val.navigated &&
    //     val.type === 'addToFavPrezBp' &&
    //     val.origin === 'fav_prez_bp' &&
    //     this.$route.fullPath === '/home/best-practice-library'
    //   ) {
    //     this.handleNavFromFavSnackbarForDecks();
    //     this.setNavFromAddToLibOrFavSnackbar({
    //       navigated: false,
    //       type: '',
    //       origin: '',
    //     });
    //   }
    // },
  },
  methods: {
    ...mapActions('bestPracticesExamples', [
      'setDecksOnLoad',
      'setDecksLoaded',
      'setBPEDeckLocalSearchText',
      'setDeckPage',
      'setIsLoadMore',
    ]),

    ...mapActions('advancedFilterStore', [
      'setFingerPrintAdditionalDetailsBPEDeckOnload',
      'setFingerPrintAdditionalDetailsBPEDeck',
      'setKnowAdvancedFilterDecks',
      'setKnowDeckIsSearching',
    ]),

    ...mapActions('commonDownloads', [
      'setNavFromDownloadSnackbarBPDck',
      'setNavFromAddToLibOrFavSnackbar',
    ]),

    async getAdvanceFilterFuntions() {
      try {
        const fingerPrintAdditionalData = await getFingerPrintAdditionalData();
        this.setFingerPrintAdditionalDetailsBPEDeck(fingerPrintAdditionalData);
        this.setFingerPrintAdditionalDetailsBPEDeckOnload(
          fingerPrintAdditionalData,
        );
      } catch (err) {
        console.log(err);
      }
    },

    async handleLoadMoreDecks() {
      if (this.getIsLoadMore) {
        if (this.origin === 'hybrid_search') {
          await this.handleLoadMore();
          const loadedSlides = this.getDecksLoaded;
          this.decksLoadedOnPage = [...loadedSlides];
        } else if (this.searchText) {
          await this.getBestPracticesExamplesDecksSearch(this.searchText)
            .then((resp) => {
              if (resp && resp.length > 0) {
                this.setDecksOnLoad([...this.decksLoadedOnPage, ...resp]);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          const activeTypes = [];
          let activeFunctions = [];
          this.advancedItems.forEach((item) => {
            if (!item.isFunction) {
              const activeType = item?.items.find(
                (typeItem) => typeItem.active,
              );
              if (activeType) {
                activeTypes.push(activeType?.title);
              }
            } else {
              const activeFun = item?.items.filter(
                (typeItem) => typeItem.active,
              );
              activeFunctions = activeFun.map((itm) => itm.title);
            }
          });
          this.doFilterChange(activeTypes, activeFunctions, 'loadmore');
          // await this.getBestPracticesExamplesDecks(this.searchText)
          //   .then((resp) => {
          //     if (resp && resp.length > 0) {
          //       this.setDecksOnLoad([...this.decksLoadedOnPage, ...resp]);
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        }
        trackKnowEvents.knowSeeMoreResults(this.currentUser, {
          [TD_PLACEMENT]: 'Decks',
        });
      }
    },
    async getBestPracticesExamplesDecksSearch(query) {
      const theme = this.getTheme();
      const searchParameters = {
        query: query || '*',
        filters: {
          and: {
            theme,
            isBestPracticeDeck: 'true',
          },
          or: {},
        },
        page: this.getDeckPage + 1,
        limit: 6,
      };
      if (this.getDeckPage < 40) {
        return getPrezentationSearch(searchParameters, this.tsKey).then(
          (response) => {
            this.setDeckPage(searchParameters.page);
            this.totalDecks = response?.data?.result?.length;
            if (response?.data?.result?.length) {
              if (response?.data?.result?.length < 6) {
                this.setIsLoadMore(false);
              }
              return response.data.result.map((tkt) => ({
                ...tkt?.prezentation,
                sections: JSON.parse(tkt?.prezentation?.sections),
                is_vector: tkt?.is_vector_search,
              }));
            }
            this.setIsLoadMore(false);
            return [];
          },
        );
      }
      this.setIsLoadMore(false);
      return [];
    },
    async getBestPracticesExamplesDecks(query) {
      const tsClient = await TypeSenseServer.getClient();
      const theme = this.getTheme();
      const searchParameters = {
        query_by: 'name, type, description, alias',
        q: query || '*',
        filter_by: `isBestPracticeDeck:true && theme:${theme}`,
        page: this.getDeckPage + 1,
        per_page: 6,
        sort_by: '',
      };
      if (this.getDeckPage < 40) {
        return tsClient
          .collections('prezentations')
          .documents()
          .search(searchParameters)
          .then((response) => {
            const { hits, found, page } = response;
            this.setDeckPage(page);
            this.totalDecks = found;
            if (hits?.length) {
              if (hits?.length < 6) {
                this.setIsLoadMore(false);
              }
              this.page++;
              return hits.map((tkt) => ({
                ...tkt?.document,
                sections: JSON.parse(tkt?.document?.sections),
              }));
            }
            this.setIsLoadMore(false);
            return [];
          });
      }
      this.setIsLoadMore(false);
      return [];
    },
    handleNavFromFavSnackbarForDecks() {
      const advItems =
        this.$store.state.advancedFilterStore.knowDeck.advancedItems;
      const advancedItemsWithMyDownloads = [...advItems].map((item) => {
        if (item.title === 'Favorites') {
          return {
            ...item,
            checkAll: true,
            items: [
              {
                active: true,
                display_title: 'filters.myFavorites',
                title: 'My Favorites',
              },
            ],
          };
        }
        return item;
      });
      this.setKnowAdvancedFilterDecks(advancedItemsWithMyDownloads);
      EventBus.$emit('KNOW_ADVANCE_FILTER_CHANGE');
    },

    handleNavFromDownloadSnackbarForDecks() {
      const advItems =
        this.$store.state.advancedFilterStore.knowDeck.advancedItems;
      const advancedItemsWithMyDownloads = [...advItems].map((item) => {
        if (item.title === 'Downloads') {
          trackKnowEvents.knowAdvanceFiltersSelected(this.currentUser, {
            [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
            [TD_COMMON_COLUMN_VALUE]: item?.title,
            [TD_PLACEMENT]: TD_FROM_SNACK_BAR_DECKS,
          });
          return {
            ...item,
            checkAll: true,
            items: [
              {
                active: true,
                display_title: 'filters.myDownloads',
                title: 'My Downloads',
              },
            ],
          };
        }
        return item;
      });
      this.setKnowAdvancedFilterDecks(advancedItemsWithMyDownloads);
      EventBus.$emit('KNOW_ADVANCE_FILTER_CHANGE');
    },
    getTheme() {
      const isNonBrandedCompany =
        this.currentUser?.company?.isNonBranded || false;
      const { hasBPGPresentations } = this.currentUser?.theme || {};
      if (isNonBrandedCompany && hasBPGPresentations) {
        return this.currentUser?.theme.code;
      }
      return this.currentUser?.company?.defaultTemplate;
    },
  },
  async mounted() {
    this.loading = true;
    this.tsKey = await getTypeSenseAPIKey();
    if (this.origin && this.origin !== 'hybrid_search') {
      this.loading = true;
      this.setKnowDeckIsSearching(true);
      await this.getBestPracticesExamplesDecks()
        .then(async (resp) => {
          if (resp && resp.length > 0) {
            this.setDecksOnLoad([...resp]);
            if (this.getBPEDeckLandingSearchText.length > 0) {
              await this.handleSearchChange(this.getBPEDeckLandingSearchText);
            }
          } else {
            this.setDecksOnLoad([]);
          }
          // this.isKnowDecksLoaded();
        })
        .catch((err) => {
          this.setDecksOnLoad([]);
          // this.isKnowDecksLoaded();
          console.log(err);
        });
      this.loading = false;
    } else {
      this.setDecksOnLoad(
        this.allDecksFromHybridSearch &&
          this.allDecksFromHybridSearch.length > 0
          ? [...this.allDecksFromHybridSearch]
          : [],
      );
      this.setDecksLoaded(
        this.decksFromHybridSerach && this.decksFromHybridSerach.length > 0
          ? [...this.decksFromHybridSerach]
          : [],
      );
      // this.isKnowDecksLoaded();
      this.loading = false;
    }

    if (this.isFilterByBPEFunctionEnabled) {
      await this.getAdvanceFilterFuntions();
    } else {
      this.setFingerPrintAdditionalDetailsBPEDeck();
      this.setFingerPrintAdditionalDetailsBPEDeckOnload();
    }

    // handling scenario where navigation is from snackbar items
    if (this.getNavFromDownloadSnackbarBPDck.navigated) {
      this.handleNavFromDownloadSnackbarForDecks();
      this.setNavFromDownloadSnackbarBPDck({ navigated: false, type: '' });
    }

    if (
      this.getNavFromAddToLibOrFavSnackbar.navigated &&
      this.getNavFromAddToLibOrFavSnackbar.type === 'addToFavPrezBp' &&
      this.getNavFromAddToLibOrFavSnackbar.origin === 'fav_prez_bp'
    ) {
      this.handleNavFromFavSnackbarForDecks();
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: false,
        type: '',
        origin: '',
      });
    }

    this.isKnowDecksLoaded();
  },
  beforeDestroy() {
    if (this.origin !== 'hybrid_search') this.setBPEDeckLocalSearchText('');
  },
};
</script>

<style lang="scss" scoped>
.knowDecksContainer {
  .deckListLoader {
    display: flex;
    justify-content: center;
    margin: 150px 0px 0px 0px;
  }
  .decks-loadmore {
    display: flex;
    justify-content: center;
    padding: 23px 0px 0px 0px;

    button {
      color: $zen-blue-default;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      text-transform: none;
    }
  }
  .no-decks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 600px;
    p {
      font-size: 20px;
    }
  }
}
</style>
