<template>
  <div
    class="know-wrapper"
    :style="{ height: `${isBannerActive ? 'calc(100vh - 120px)' : '100%'}` }"
    @click="
      getBPESlideLandingSearchText.length > 0
        ? updateBPELandingSearchText()
        : null
    "
  >
    <div
      class="know-container"
      :class="showQuickSelection ? 'quick-panel-active' : ''"
    >
      <v-row dense>
        <v-col>
          <v-sheet v-if="viewType === 'Slides'">
            <v-chip-group show-arrows :value="selectedTrendingIdeas">
              <v-chip
                v-for="tag in getTrendingKeywords"
                :class="
                  selectedTrendingIdeas.length === 1 &&
                  (selectedTrendingIdeas[0].toLowerCase() ===
                    tag.name.toLowerCase() ||
                    selectedTrendingIdeas[0] === tag.category)
                    ? 'active'
                    : ''
                "
                :key="tag.category"
                @click="() => handleTagSelection(tag)"
              >
                {{ tag.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
          <KnowDecksIdeaChips
            v-if="viewType === 'Decks'"
            :reset-parent-filters="resetParentFilters"
            :do-filter-change="doFiltering"
            :key="decksViewKey"
          />
        </v-col>
      </v-row>
      <div class="pageFilters">
        <div class="leftFilters">
          <div
            v-if="viewType === 'Decks'"
            class="searchBox"
            data-pendo-id="know-type-filter"
          >
            <v-text-field
              :value="searchModel"
              append-icon=""
              single-line
              hide-details
              :placeholder="
                $t(
                  'bestPracticesGuide.knowContainer.searchBestPracticeExamples',
                )
              "
              class="bp-search-bar__input pl-4 bp-search-bar--auto"
              @input="(e) => handleSearchChange(e)"
              @focus="searchWithTextBoxActive = true"
              @blur="searchWithTextBoxActive = false"
              id="bpl-search"
            >
              <template #append>
                <v-icon
                  class="icon"
                  v-text="`mdi-close`"
                  @click="clearTextFieldSearch"
                  name="prez-cross-icon"
                  color="#21a7e0"
                  v-show="searchModel.length > 0"
                />
                <v-progress-circular
                  v-if="loading"
                  :size="20"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-icon
                  class="icon"
                  v-else
                  v-text="`mdi-magnify`"
                  name="prez-magnify-glass"
                  :color="
                    searchWithTextBoxActive === true ? '#21a7e0' : 'inherit'
                  "
                />
              </template>
            </v-text-field>
          </div>
          <SlidesSearch v-else></SlidesSearch>
        </div>
        <div class="rightFilters">
          <div class="viewRadios">
            <v-radio-group
              row
              v-model="viewType"
              data-pendo-id="best-practices-examples-slides"
            >
              <NewFeatureBadge
                module-name="release_2200"
                :feature-id="'release_2200'"
                :offsetY="'0'"
                :size="'small'"
              >
                <v-radio
                  :label="$t('prezentationGallery.decksText')"
                  value="Decks"
                ></v-radio>
              </NewFeatureBadge>
              <v-radio
                :label="$t('prezentationGallery.slidesText')"
                value="Slides"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="quick-action-cta">
            <button
              class="filter-btn"
              :name--auto="`bpe-filter-filter-btn${
                toolType === 'filter' ? '--active' : ''
              }`"
              data-pendo-id="filter-sort-action"
              :class="toolType === 'filter' ? 'filter-btn-active' : ''"
              @click="handleQuickSelection('filter')"
            >
              <img src="/assets/img/filter-sort.svg" alt />
              Filter & Sort
            </button>
          </div>
        </div>
      </div>
      <div class="lists">
        <KnowDecks
          v-if="viewType === 'Decks'"
          :style="{ width: `${advancedFilterOpened === true ? 85 : 100}%` }"
          :isKnowDecksLoaded="isKnowDecksLoaded"
          :handleDetailClick="handleOnClick"
          :handleSearchChange="handleSearchChange"
          :key="decksViewKey"
          :searchText="searchModel"
          :doFilterChange="doFiltering"
        />
        <div
          v-else
          class="slideslist"
          :style="{ width: `${advancedFilterOpened === true ? 85 : 100}%` }"
        >
          <Loading v-if="isSearching" />
          <div class="list-container" v-else>
            <template v-if="slidesList.length > 0">
              <v-item-group active-class="primary">
                <v-container fluid>
                  <v-row>
                    <v-col
                      v-for="(slide, i) in slidesList"
                      :key="`${slide.unique_id}__${i}`"
                      class="col-xs-12 col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4"
                      :style="[
                        $vuetify.breakpoint.xs ||
                        $vuetify.breakpoint.sm ||
                        $vuetify.breakpoint.md
                          ? { 'min-width': '300px' }
                          : {},
                      ]"
                    >
                      <v-item>
                        <KnowSlideCard
                          :slide="slide"
                          :companyTheme="companyTheme"
                          :handleDetailClick="handleOnClick"
                          :metaData="slidesMetadata[slide.unique_id] || {}"
                          :data-pendo-id="
                            i === 0 ? 'know-slide-card' : undefined
                          "
                        />
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </template>
            <v-row v-else>
              <v-col class="no-result">
                <img
                  src="/assets/img/know/no-results-found-know-decks.svg"
                  width="400px"
                />
                <h2>
                  {{ $t('bestPracticesGuide.knowContainer.noResultsFound') }}
                </h2>
                <p>{{ $t('search.dontLoseHeart') }}</p>
              </v-col>
            </v-row>
            <v-row v-if="slidesList.length > 0">
              <v-col cols="12" align="center" justify="center">
                <v-btn
                  style="text-transform: none"
                  :disabled="isFetchingMore || !seeMoreSlides"
                  @click="loadMoreSlides"
                  color="#21a7e0"
                  rounded
                  outlined
                  height="32"
                >
                  {{
                    seeMoreSlides
                      ? $t('bestPracticesGuide.knowContainer.seeMoreResults')
                      : $t('bestPracticesGuide.knowContainer.noMoreResults')
                  }}
                </v-btn>
                <p
                  class="mt-2"
                  v-if="
                    !seeMoreSlides &&
                    !knowCategory &&
                    trendingAllFilter.checked &&
                    !searchModel.length &&
                    !isSlideAdvancedFilterChanged
                  "
                >
                  {{ $t('bestPracticesGuide.knowContainer.limitedResults') }}
                  <a class="text-link" @click="handleScrollToOption('search')">
                    {{ $t('bestPracticesGuide.knowContainer.searchBar') }}
                  </a>
                  {{ $t('bestPracticesGuide.knowContainer.or') }}
                  <a class="text-link" @click="handleScrollToOption('filter')">
                    {{ $t('bestPracticesGuide.knowContainer.filters') }}
                  </a>
                  {{ $t('bestPracticesGuide.knowContainer.onTop') }}
                </p>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>

    <QuickSelectionCntr
      class="quick-panel"
      v-if="showQuickSelection"
      :toolType="toolType"
      @close="handleQuickSelection"
    >
      <KnowAdvancedFilters v-if="viewType === 'Slides'" />
      <KnowDeckAdvancedFilters
        v-if="viewType === 'Decks'"
        :key="decksViewKey"
      />
    </QuickSelectionCntr>
  </div>
</template>

<script>
import { orderBy, uniqBy, debounce } from 'lodash';
import { graphqlOperation } from '@aws-amplify/api';
import { mapState, mapActions, mapGetters } from 'vuex';
import { performSearch } from '../../../graphql/queries';
import Loading from '../../common/Loading.vue';
import KnowSlideCard from './KnowSlideCard.vue';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import {
  getKnowSlideMetaData,
  getKnowUserSearchTerms,
  handleKnowSearchBySort,
  updateKnowSearchTerms,
  logSearchQuery,
  getFingerPrintAdditionalData,
  getHiddenIdeas,
  getPrezentationSearch,
} from '../../../utils/api-helper';
import { capitalizeFirstLetter } from '../../../utils/common';
import EventBus from '../../common/event-bus';
import { trackKnowEvents } from '../../common/Analytics/KnowEvents';
import {
  TD_A_TO_Z,
  TD_Z_TO_A,
  TD_POPULAR,
  TD_QUERY,
  TD_REC,
  TD_SORTTYPE,
  TD_PLACEMENT,
  TD_NORESULTSFOUND,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_SECTION_CHANGED,
  TD_FROM_SNACK_BAR_SLIDES,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import usersApi from '../../../API/users-api';
import KnowDecksIdeaChips from './KnowDecksIdeaChips.vue';
import KnowDecks from './KnowDecks.vue';
import { GraphQLService } from '../../../services/GraphQLService';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import QuickSelectionCntr from '../../common/QuickSelectionTools/QuickSelectionCntr.vue';
import KnowAdvancedFilters from '../../common/QuickSelectionTools/Filter&Sort/KnowAdvancedFilters.vue';
import KnowDeckAdvancedFilters from '../../common/QuickSelectionTools/Filter&Sort/KnowDeckAdvancedFilters.vue';
import SlidesSearch from '../Slides/SlidesSearch.vue';
import {
  getTypeSenseAPIKey,
  TypeSenseServer,
} from '../../../pages/components/TypeSenseInit';

const defaultIdeasList = [
  {
    searchTerm: 'Competitive Landscape',
    category: 'competitive_landscape',
    count: 0,
  },
  { searchTerm: 'Executive Summary', category: 'executive_summary', count: 0 },
  { searchTerm: 'Market Size', category: 'market_size', count: 0 },
  { searchTerm: 'Mission', category: 'mission', count: 0 },
  { searchTerm: 'Options', category: 'options', count: 0 },
  { searchTerm: 'Strategy', category: 'strategy', count: 0 },
  { searchTerm: 'Vision', category: 'vision', count: 0 },
  { searchTerm: 'Scorecard', category: 'scorecard', count: 0 },
  { searchTerm: 'Objectives', category: 'objectives', count: 0 },
  { searchTerm: 'Value Proposition', category: 'value_proposition', count: 0 },
];

const handleDebounce = debounce((callback) => {
  callback();
}, 1000);

export default {
  name: 'Know',
  components: {
    Loading,
    KnowSlideCard,
    KnowDecksIdeaChips,
    KnowDecks,
    NewFeatureBadge,
    QuickSelectionCntr,
    KnowAdvancedFilters,
    KnowDeckAdvancedFilters,
    SlidesSearch,
  },
  data: () => ({
    trendingKeywords: [],
    trendingKeywordsAll: [],
    searchOrigin: '',
    trendingAllFilter: {
      name: 'All',
      checked: true,
      disabled: false,
      category: 'all',
    },
    hiddenIdeas: [],
    sourceList: [
      { name: 'prezent.ai', key: 'prezent', disabled: true, checked: true },
    ],
    isFetchingMore: false,
    tsClient: '',
    slidesList: [],
    slidesMetadata: {},
    seeMoreSlides: true,
    loading: false,
    pageSize: Number(process.env.VUE_APP_NUM_OF_SLIDES),
    companyTheme: '',
    knowCategory: '',
    knowQuery: '',
    // flag to exclude logging search when component open for first time
    logSearchQueryFlag: false,
    logSearchQueryFlagIsLoading: false,
    blacklistCategory: [
      'nature',
      'mountain',
      '80s',
      'sports',
      '4th_of_july',
      'thanksgiving',
      'christmas',
      'sea',
      'movie',
      'space',
      'race_car',
      'prezfingerprinttest',
    ],
    matomoFilterMap: {
      'Most Popular': TD_POPULAR,
      Recommended: TD_REC,
      'A-Z': TD_A_TO_Z,
      'Z-A': TD_Z_TO_A,
    },
    viewType: '',
    advancedFilterOpened: false,
    searchModel: '',
    searchWithTextBoxActive: false,
    slidesListLoaded: [],
    advancedFiltersActivePanels: [0, 1],
    showQuickSelection: false,
    toolType: null,
    logSearchPayload: {},
    decksViewKey: 0,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from?.name?.indexOf('best-practice-library') === -1 &&
        from?.name?.indexOf('search') === -1
      ) {
        vm.resetKnow();
      }
    });
  },
  async beforeMount() {
    // handling scenario where navigation is from snackbar items
    if (this.getNavFromDownloadSnackbarBPDck.navigated) {
      this.setBPEviewType('Decks');
      this.showQuickSelection = true;
      this.toolType = 'filter';
    }

    if (this.getNavFromDownloadSnackbarBPSld.navigated) {
      this.setBPEviewType('Slides');
      this.showQuickSelection = true;
      this.toolType = 'filter';
    }

    if (
      this.getNavFromAddToLibOrFavSnackbar.navigated &&
      this.getNavFromAddToLibOrFavSnackbar.type === 'addToFavPrezBp' &&
      this.getNavFromAddToLibOrFavSnackbar.origin === 'fav_prez_bp'
    ) {
      this.setBPEviewType('Decks');
      this.showQuickSelection = true;
      this.toolType = 'filter';
    }

    if (
      this.getNavFromAddToLibOrFavSnackbar.navigated &&
      this.getNavFromAddToLibOrFavSnackbar.type === 'addToFavSlidesBp' &&
      this.getNavFromAddToLibOrFavSnackbar.origin === 'fav_slides_bp'
    ) {
      this.setBPEviewType('Slides');
      this.showQuickSelection = true;
      this.toolType = 'filter';
    }

    if (this.getSelectedViewType === 'Slides') {
      this.viewType = 'Slides';
    } else {
      this.viewType = 'Decks';
    }
    EventBus.$on('DO_KNOW_SEARCH', (payload) => {
      this.logSearchPayload.type = 'Know';
      this.logSearchPayload.searchTerm = payload?.name;
      this.viewType = 'Slides';
      this.knowCategory = payload.category
        ? payload.category
        : payload.item.name;
      this.knowQuery = payload.name;
      this.resetKnow();
      if (!this.isTrialExpired && this.isKnowEnabled) {
        if (this.knowQuery) {
          this.setSelectedTrendingIdeas([this.knowQuery]);
          this.updateIsSearching(true);
        }
        this.incrementSearchCount();
        this.initialData();
      } else {
        this.$router.push('/home', () => {});
      }
    });
  },
  watch: {
    async getNavFromDownloadSnackbarBPSld(val) {
      if (
        val.navigated &&
        this.$route.fullPath === '/home/best-practice-library'
      ) {
        if (this.viewType !== 'Slides') {
          this.setBPEviewType('Slides');
          this.viewType = 'Slides';
        } else {
          this.setBPEviewType('Slides');
          this.viewType = 'Slides';
          this.setBPEviewType(this.viewType);
          this.advancedFilterOpened = false;
          this.searchModel = '';
          this.updateSortByValue('Recommended');
          this.setSelectedTrendingIdeas(['All']);
          this.knowQuery = '';
          this.setSelectedOutlinesAndSearch('All');
          this.resetAdvancedFilter();

          // await this.makesApiCallsForSlides();

          // handling scenario where navigation is from snackbar items
          if (val.navigated) {
            this.getSearchTerms();
            this.handleNavFromDownloadSnackbarForSlides();
            this.setNavFromDownloadSnackbarBPSld({
              navigated: false,
              type: '',
            });
          }
        }

        this.showQuickSelection = true;
        this.toolType = 'filter';
      }
    },

    getNavFromDownloadSnackbarBPDck(val) {
      if (
        val.navigated &&
        this.$route.fullPath === '/home/best-practice-library'
      ) {
        this.setBPEviewType('Decks');
        this.viewType = 'Decks';
        this.showQuickSelection = true;
        this.toolType = 'filter';
        this.resetAdvancedFilter();

        this.decksViewKey++;
      }
    },

    async getNavFromAddToLibOrFavSnackbar(val) {
      if (
        val.navigated &&
        val.type === 'addToFavSlidesBp' &&
        val.origin === 'fav_slides_bp' &&
        this.$route.fullPath === '/home/best-practice-library'
      ) {
        if (this.viewType !== 'Slides') {
          this.setBPEviewType('Slides');
          this.viewType = 'Slides';
        } else {
          this.setBPEviewType('Slides');
          this.viewType = 'Slides';
          this.setBPEviewType(this.viewType);
          this.advancedFilterOpened = false;
          this.searchModel = '';
          this.updateSortByValue('Recommended');
          this.setSelectedTrendingIdeas(['All']);
          this.knowQuery = '';
          this.setSelectedOutlinesAndSearch('All');
          this.resetAdvancedFilter();

          // await this.makesApiCallsForSlides();

          // handling scenario where navigation is from snackbar items
          if (
            val.navigated &&
            val.type === 'addToFavSlidesBp' &&
            val.origin === 'fav_slides_bp'
          ) {
            this.getSearchTerms();
            this.handleNavFromFavSnackbarForSlides();
            this.setNavFromAddToLibOrFavSnackbar({
              navigated: false,
              type: '',
              origin: '',
            });
          }
        }

        this.showQuickSelection = true;
        this.toolType = 'filter';
      } else if (
        val.navigated &&
        val.type === 'addToFavPrezBp' &&
        val.origin === 'fav_prez_bp' &&
        this.$route.fullPath === '/home/best-practice-library'
      ) {
        this.setBPEviewType('Decks');
        this.viewType = 'Decks';
        this.showQuickSelection = true;
        this.toolType = 'filter';
        this.resetAdvancedFilter();

        this.decksViewKey++;
      }
    },

    async viewType(val) {
      if (
        this.getBPESlideLandingSearchText.length <= 0 &&
        !this.knowBackClicked
      ) {
        this.getHiddenIdeasSearch();
        this.setBPEviewType(this.viewType);
        this.advancedFilterOpened = false;
        this.searchModel = '';
        this.setDeckPage(0);
        this.setIsLoadMore(true);
        this.knowQuery = '';
        this.sortByValue = 'Recommended';
        if (val === 'Decks') {
          // do stuff for decks type
        } else if (val === 'Slides') {
          // await this.makesApiCallsForSlides();

          // handling scenario where navigation is from snackbar items
          this.resetAdvancedFilter();
          this.updateSortByValue('Recommended');
          this.setSelectedTrendingIdeas(['All']);
          this.setSelectedOutlinesAndSearch('All');
          if (this.getNavFromDownloadSnackbarBPSld.navigated) {
            this.getSearchTerms();
            this.handleNavFromDownloadSnackbarForSlides();
            this.setNavFromDownloadSnackbarBPSld({
              navigated: false,
              type: '',
            });
          } else if (
            this.getNavFromAddToLibOrFavSnackbar.navigated &&
            this.getNavFromAddToLibOrFavSnackbar.type === 'addToFavSlidesBp' &&
            this.getNavFromAddToLibOrFavSnackbar.origin === 'fav_slides_bp'
          ) {
            this.getSearchTerms();
            this.handleNavFromFavSnackbarForSlides();
            this.setNavFromAddToLibOrFavSnackbar({
              navigated: false,
              type: '',
              origin: '',
            });
          } else {
            await this.makesApiCallsForSlides();
          }
        }
      }
    },

    leftSideBarStatus(val) {
      if (!val) {
        this.showQuickSelection = false;
        this.toolType = '';
      }
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'companyData',
      'themes',
      'isTrialExpired',
      'leftSideBarStatus',
      'fingerPrintAdditionalData',
      'isBannerActive',
    ]),
    ...mapState('knowStore', [
      // 'selectedSource',
      'selectedTrendingIdeas',
      'knowCategories',
    ]),
    ...mapState('bestPracticesExamples', ['knowBackClicked']),
    ...mapGetters('users', ['isKnowEnabled', 'isFilterByBPEFunctionEnabled']),
    ...mapGetters('bestPracticesExamples', [
      'getDecksLoaded',
      'getSelectedViewType',
      'getBPEDeckLandingSearchText',
      'getBPESlideLandingSearchText',
      'getSelectedOutlines',
      'getPreviousRoute',
      'getDeckPage',
      'getBPLSnapshot',
    ]),

    ...mapGetters('commonDownloads', [
      'getNavFromDownloadSnackbarBPSld',
      'getNavFromDownloadSnackbarBPDck',
      'getNavFromAddToLibOrFavSnackbar',
    ]),
    getTrendingKeywords() {
      if (this.trendingKeywordsAll && this.trendingKeywordsAll.length) {
        return [
          ...this.trendingKeywordsAll.filter(
            (payload) =>
              !this.hiddenIdeas.includes(payload.category.toLowerCase()),
          ),
        ];
      }
      return [];
    },
    isSearching: {
      get() {
        if (this.viewType === 'Slides') {
          return this.$store.state.advancedFilterStore.know.isSearching;
        }
        return this.$store.state.advancedFilterStore.knowDeck.isSearching;
      },
      set() {},
    },
    advancedItems: {
      get() {
        if (this.viewType === 'Slides') {
          return this.$store.state.advancedFilterStore.know.advancedItems;
        }
        return this.$store.state.advancedFilterStore.knowDeck.advancedItems;
      },
      set() {},
    },
    advancedItemsOnLoad: {
      get() {
        if (this.viewType === 'Slides') {
          return this.$store.state.advancedFilterStore.know.advancedItemsOnLoad;
        }
        return this.$store.state.advancedFilterStore.knowDeck
          .advancedItemsOnLoad;
      },
      set() {},
    },
    sortByValue: {
      get() {
        if (this.viewType === 'Slides') {
          return this.$store.state.advancedFilterStore.know.sortByValue;
        }
        return this.$store.state.advancedFilterStore.knowDeck.sortByValue;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setFromDateMenu', value);
      },
    },
    isSlideAdvancedFilterChanged() {
      const isActive = this.advancedItems.some((item) => item.active);
      return isActive;
    },
  },
  methods: {
    ...mapActions('knowStore', [
      'setSlidesList',
      'setSlidesMeta',
      'setSelectedTrendingIdeas',
      'resetKnow',
      'setSourceDisplayNameRef',
    ]),
    ...mapActions('slidesStore', ['setSlideViewOrigin']),
    ...mapActions('bestPracticesExamples', [
      'setSelectedOutlinesAndSearch',
      'setDecksLoaded',
      'setBPEviewType',
      'setBPESlideLocalSearchText',
      'setSortByFilterDecks',
      'setDeckPage',
      'setIsLoadMore',
      'saveBPLStateSnapshot',
      'setKnowBackClicked',
    ]),
    ...mapActions('users', [
      'setCurrentUser',
      'setBPESlideRecentQueriesOnMount',
      'updateRecentBPESlideQueries',
    ]),
    ...mapActions('advancedFilterStore', [
      'setFingerPrintAdditionalDetailsBPEDeck',
      'setFingerPrintAdditionalDetailsBPEDeckOnload',
      'setKnowAdvancedFilterSlides',
      'setKnowDeckIsSearching',
    ]),

    ...mapActions('commonDownloads', [
      'setNavFromDownloadSnackbarBPSld',
      'setNavFromDownloadSnackbarBPDck',
      'setNavFromAddToLibOrFavSnackbar',
    ]),
    async getHiddenIdeasSearch() {
      try {
        const theme = this.getThemeId('hasBPGSlides');
        const hiddenIdea = await getHiddenIdeas({
          companyID: this.currentUser?.company?.id,
          themeID: theme,
          isBPLSlides: true,
        });
        if (hiddenIdea && hiddenIdea.data.hiddenIdeas.length) {
          this.hiddenIdeas = [...hiddenIdea.data.hiddenIdeas];
        } else {
          this.hiddenIdeas = [];
        }
      } catch (err) {
        console.log(err);
      }
    },
    // assigning values from snapshot
    assignKnowSnapshotToData() {
      Object.assign(this.$data, this.getBPLSnapshot.KnowContainer);
    },
    handleOnClick() {
      const snapshot = {
        KnowContainer: this.$data,
      };
      this.saveBPLStateSnapshot(snapshot);
    },
    handleNavFromFavSnackbarForSlides() {
      const advItems = this.$store.state.advancedFilterStore.know.advancedItems;
      const advancedItemsWithMyDownloads = [...advItems].map((item) => {
        if (item.title === 'Favorites') {
          return {
            ...item,
            checkAll: true,
            items: [
              {
                active: true,
                display_title: 'filters.myFavorites',
                title: 'My Favorites',
              },
            ],
          };
        }
        return item;
      });
      this.setKnowAdvancedFilterSlides(advancedItemsWithMyDownloads);
      this.onAdvancedFilerChange();
    },

    handleNavFromDownloadSnackbarForSlides() {
      const advItems = this.$store.state.advancedFilterStore.know.advancedItems;
      const advancedItemsWithMyDownloads = [...advItems].map((item) => {
        if (item.title === 'Downloads') {
          trackKnowEvents.knowAdvanceFiltersSelected(this.currentUser, {
            [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
            [TD_COMMON_COLUMN_VALUE]: item?.title,
            [TD_PLACEMENT]: TD_FROM_SNACK_BAR_SLIDES,
          });
          return {
            ...item,
            checkAll: true,
            items: [
              {
                active: true,
                display_title: 'filters.myDownloads',
                title: 'My Downloads',
              },
            ],
          };
        }
        return item;
      });
      this.setKnowAdvancedFilterSlides(advancedItemsWithMyDownloads);
      this.onAdvancedFilerChange();
    },

    getsortByValue() {
      if (this.sortByValue === 'Recommended') return 'CHOICE_DESC';
      if (this.sortByValue === 'Most Popular') return 'POPULARITY';
      if (this.sortByValue === 'A-Z') return 'ASC';
      if (this.sortByValue === 'Z-A') return 'DESC';
      return 'CHOICE_DESC';
    },
    async getAdvanceFilterFuntions() {
      try {
        const fingerPrintAdditionalData = await getFingerPrintAdditionalData();
        this.setFingerPrintAdditionalDetailsBPEDeck(fingerPrintAdditionalData);
        this.setFingerPrintAdditionalDetailsBPEDeckOnload(
          fingerPrintAdditionalData,
        );
      } catch (err) {
        console.log(err);
      }
    },
    updateBPELandingSearchText() {
      this.setBPESlideLocalSearchText('');
    },
    updateSortByValue(value, origin) {
      if (this.viewType === 'Slides')
        this.$store.dispatch('advancedFilterStore/setKnowSortByValue', value);
      else {
        this.setDeckPage(0);
        this.setIsLoadMore(true);
        if (origin !== 'tab-change') {
          this.doFiltering([], ['All'], 'sort');
        }
      }
    },
    updateIsSearching(value) {
      if (this.viewType === 'Slides')
        this.$store.dispatch('advancedFilterStore/setKnowIsSearching', value);
      else
        this.$store.dispatch(
          'advancedFilterStore/setKnowDeckIsSearching',
          value,
        );
    },
    filterBestPracticesWithTextField: debounce((e, thisInstance) => {
      thisInstance.updateIsSearching(true);
      thisInstance.searchModel = e;
      thisInstance.setIsLoadMore(true);
      thisInstance.loading = true;
      thisInstance.getBestPracticesExamplesDecks(e).then((decks) => {
        thisInstance.setDecksLoaded([...decks]);
        trackKnowEvents.knowDeckSearch(thisInstance.currentUser, {
          [TD_QUERY]: e,
          [TD_NORESULTSFOUND]: decks && decks.length > 0 ? '1' : '0',
        });
        thisInstance.updateIsSearching(false);
        thisInstance.loading = false;
      });
    }, 500),

    // filterBestPracticeSlidesWithTextField: debounce((e, thisInstance) => {
    //   thisInstance.$router
    //     .replace({ query: null, category: null })
    //     .catch(() => {});
    //   if (thisInstance.getBPESlideLandingSearchText.length === 0)
    //     EventBus.$emit('CLEAR_SEARCH');
    //   if (e && e.length > 1) {
    //     const ideaFound = [...thisInstance.knowCategories].filter((item) =>
    //       item.toLowerCase().replace('_', ' ').includes(e.toLowerCase()),
    //     );
    //     if (ideaFound && ideaFound.length > 0) {
    //       thisInstance.sortByValue = 'Recommended';
    //       const foundIdea = thisInstance.trendingKeywordsAll.find(
    //         (kw) => kw.name.toLowerCase() === e.toLowerCase(),
    //       )
    //         ? e
    //         : 'All';
    //       thisInstance.setSelectedTrendingIdeas([foundIdea]);
    //       thisInstance.knowQuery = foundIdea;
    //       thisInstance.setSelectedSortBy('Recommended');
    //       thisInstance.resetAdvancedFilter();
    //       thisInstance.getSlidesList(false, {
    //         type: 'searchBox',
    //         idea: ideaFound,
    //       });
    //     } else {
    //       thisInstance.setSelectedTrendingIdeas(['All']);
    //       thisInstance.slidesList = [];
    //       const logPayload = {
    //         type: 'Best practice guides local',
    //         searchTermResult: false,
    //         searchTerm: e,
    //       };
    //       logSearchQuery(logPayload)
    //         .then()
    //         .catch((err) => console.log(err))
    //         .finally(() => {});
    //     }
    //     trackKnowEvents.knowSlideSearch(thisInstance.currentUser, {
    //       [TD_QUERY]: e,
    //       [TD_NORESULTSFOUND]: ideaFound && ideaFound.length > 0 ? '1' : '0',
    //     });
    //   }
    // }, 1000),
    async getBestPracticesExamplesDecks(query) {
      const theme = this.getTheme('hasBPGPresentations');
      const searchParameters = {
        query: query || '*',
        filters: {
          and: {
            theme,
            isBestPracticeDeck: 'true',
          },
          or: {},
        },
        page: 1,
        limit: 6,
      };
      return getPrezentationSearch(searchParameters, this.tsClient).then(
        (response) => {
          this.setDeckPage(searchParameters.page);
          this.totalDecks = response?.data?.result?.length;
          if (response?.data?.result?.length) {
            return response.data.result.map((tkt) => ({
              ...tkt?.prezentation,
              sections: JSON.parse(tkt?.prezentation?.sections),
              is_vector: tkt?.is_vector_search,
            }));
          }
          return [];
        },
      );
    },

    handleSearchChange(e) {
      this.searchModel = e;
      if (this.viewType === 'Decks') {
        const functionFilter = this.advancedItems
          .filter((ele) => ele.display_title === 'Function')[0]
          ?.items.filter((itm) => itm.display_title === 'All')[0];

        if (
          this.sortByValue !== 'Recommended' ||
          (!functionFilter?.active && this.isFilterByBPEFunctionEnabled)
        ) {
          this.setSelectedOutlinesAndSearch('All');
          this.resetAdvancedFilter();
        }
        this.filterBestPracticesWithTextField(this.searchModel, this);
      }
    },

    async clearTextFieldSearch() {
      this.searchModel = '';
      this.setDeckPage(0);
      if (this.viewType === 'Decks') {
        this.doFiltering([], ['All']);
      }
    },
    isKnowDecksLoaded() {
      if (this.viewType === 'Decks') {
        this.updateIsSearching(false);
      }
    },
    async resetAdvancedFilter(loadSlides = false, type = '') {
      if (this.viewType === 'Slides') {
        this.$store.dispatch('advancedFilterStore/resetKnowAdvancedItems');
      } else {
        if (this.isFilterByBPEFunctionEnabled) {
          this.$store.dispatch(
            'advancedFilterStore/resetKnowDeckAdvancedItems',
            this.fingerPrintAdditionalData,
          );
        }
        if (!this.isFilterByBPEFunctionEnabled) {
          this.$store.dispatch(
            'advancedFilterStore/resetKnowDeckAdvancedItems',
          );
        }
      }
      if (this.viewType === 'Decks') {
        this.setIsLoadMore(true);
        this.setDeckPage(0);
        if (type !== 'tab-change') {
          const bpeDecks = await this.doFilterChange(false, false);
          this.setDecksLoaded(bpeDecks);
        }
      } else if (loadSlides) this.getSlidesList();
    },
    resetParentFilters() {
      this.searchModel = '';
      this.setIsLoadMore(true);
      this.setDeckPage(0);
      this.sortByValue = 'Recommended';
    },
    onAdvancedFilerChange() {
      this.updateIsSearching(true);
      EventBus.$emit('CLEAR_SEARCH');
      if (this.$route?.query?.category && this.$route?.query?.query)
        this.$router.push('/home/best-practice-library');
      if (this.viewType === 'Decks') {
        this.onAdvancedFilerChangeDecks();
      } else {
        this.onAdvancedFilerChangeSlides();
      }
    },
    onAdvancedFilerChangeDecks() {
      this.setIsLoadMore(true);
      this.setDeckPage(0);
      this.searchModel = '';
      this.sortByValue = 'Recommended';
      this.setSelectedOutlinesAndSearch('All');
      const activeTypes = [];
      let activeFunctions = [];
      this.advancedItems.forEach((item) => {
        if (!item.isFunction) {
          const activeType = item?.items.find((typeItem) => typeItem.active);
          if (activeType) {
            activeTypes.push(activeType?.title);
          }
        } else {
          const activeFun = item?.items.filter((typeItem) => typeItem.active);
          activeFunctions = activeFun.map((itm) => itm.title);
        }
      });
      this.doFiltering(activeTypes, activeFunctions);
    },
    async doFiltering(activeTypes, activeFunctions, origin = '') {
      let filteredArray = [];
      if (
        !activeFunctions.includes('All') &&
        this.isFilterByBPEFunctionEnabled &&
        origin !== 'sort'
      ) {
        const selectedArray = activeFunctions.filter((item) => item !== 'All');
        const selectedArrayLowerCase = selectedArray.map((item) =>
          item.toLowerCase(),
        );
        if (
          activeTypes.includes('My Favorites') &&
          activeTypes.includes('My Downloads')
        ) {
          filteredArray = await this.doFilterChange(
            true,
            true,
            'functions',
            selectedArrayLowerCase,
          );
        } else if (activeTypes.includes('My Favorites')) {
          filteredArray = await this.doFilterChange(
            false,
            true,
            'functions',
            selectedArrayLowerCase,
          );
        } else if (activeTypes.includes('My Downloads')) {
          filteredArray = await this.doFilterChange(
            true,
            false,
            'functions',
            selectedArrayLowerCase,
          );
        } else {
          filteredArray = await this.doFilterChange(
            false,
            false,
            'functions',
            selectedArrayLowerCase,
          );
        }
        if (origin === 'loadmore') {
          this.setDecksLoaded([...this.getDecksLoaded, ...filteredArray]);
        } else {
          this.setDecksLoaded([...filteredArray]);
        }
        return;
      }
      if (
        activeTypes.includes('My Favorites') &&
        activeTypes.includes('My Downloads')
      ) {
        const filteredItems = await this.doFilterChange(true, true);
        if (origin === 'loadmore') {
          this.setDecksLoaded([...this.getDecksLoaded, ...filteredItems]);
        } else {
          this.setDecksLoaded([...filteredItems]);
        }
        return;
      }
      if (activeTypes.includes('My Favorites')) {
        const filteredItems = await this.doFilterChange(false, true);
        if (origin === 'loadmore') {
          this.setDecksLoaded([...this.getDecksLoaded, ...filteredItems]);
        } else {
          this.setDecksLoaded([...filteredItems]);
        }
        return;
      }
      if (activeTypes.includes('My Downloads')) {
        const filteredItems = await this.doFilterChange(true, false);
        if (origin === 'loadmore') {
          this.setDecksLoaded([...this.getDecksLoaded, ...filteredItems]);
        } else {
          this.setDecksLoaded([...filteredItems]);
        }
        return;
      }
      if (
        this.origin === 'tag' ||
        this.getSelectedOutlines.filter((ele) => ele !== 'All').length
      ) {
        filteredArray = await this.doFilterChange(false, false, 'tag');
        if (origin === 'loadmore') {
          this.setDecksLoaded([...this.getDecksLoaded, ...filteredArray]);
        } else {
          this.setDecksLoaded(filteredArray);
        }
        return;
      }
      if (this.sortByValue !== 'Recommended') {
        filteredArray = await this.doFilterChange(
          false,
          false,
          this.sortByValue,
        );
        if (origin === 'loadmore') {
          this.setDecksLoaded([...this.getDecksLoaded, ...filteredArray]);
        } else {
          this.setDecksLoaded(filteredArray);
        }
      } else {
        filteredArray = await this.doFilterChange(false, false);
        if (origin === 'loadmore') {
          this.setDecksLoaded([...this.getDecksLoaded, ...filteredArray]);
        } else {
          this.setDecksLoaded(filteredArray);
        }
      }
    },

    handleGetCompanyTheme() {
      if (
        this.currentUser?.company?.isNonBranded &&
        this.currentUser?.theme?.hasBPGSlides
      ) {
        this.companyTheme = this.currentUser?.theme.code;
      } else {
        this.companyTheme = this.currentUser?.company?.defaultTemplate;
      }
    },
    getTheme(type) {
      const isNonBrandedCompany =
        this.currentUser?.company?.isNonBranded || false;
      const userSelectedTheme = this.currentUser?.theme || {};
      if (isNonBrandedCompany && userSelectedTheme[type]) {
        return this.currentUser?.theme.code;
      }
      return this.currentUser?.company?.defaultTemplate;
    },
    getThemeId(type) {
      const isNonBrandedCompany =
        this.currentUser?.company?.isNonBranded || false;
      const userSelectedTheme = this.currentUser?.theme || {};
      if (isNonBrandedCompany && userSelectedTheme[type]) {
        return this.currentUser?.theme.id;
      }
      const matchingTheme = this.themes.find(
        (theme) => theme.code === this.currentUser?.company?.defaultTemplate,
      );
      return matchingTheme ? matchingTheme.id : null;
    },
    async doFilterChange(isDownload, isFavorite, action = '', functions = []) {
      const theme = this.getTheme('hasBPGPresentations');
      let filterBy = `isBestPracticeDeck:=true && theme:=${theme}`;
      let sortBy = '';
      if (action === 'functions' && functions.length) {
        filterBy += `&& function:=[${functions}]`;
      } else if (action === 'functions' && !functions.length) {
        return [];
      } else if (action === 'tag') {
        filterBy += ` && type:=[${this.getSelectedOutlines.filter(
          (ele) => ele !== 'All',
        )}]`;
      }
      console.log(action, isDownload, isFavorite);
      if (action === 'Most Popular') {
        sortBy = 'viewCount: desc';
      } else if (action === 'A-Z') {
        sortBy = 'name: asc';
      } else if (action === 'Z-A') {
        sortBy = 'name: desc';
      } else if (isDownload) {
        filterBy += ` && downloaded_by:=${this.currentUser?.user?.num_id}`;
      }
      if (isFavorite) {
        filterBy += ` && favourites_by:=${this.currentUser?.user?.num_id}`;
      }
      this.updateIsSearching(true);
      const tsClient = await TypeSenseServer.getClient();
      const searchParameters = {
        query_by: 'name, type, description, alias',
        q: this.searchModel || '*',
        filter_by: filterBy,
        page: this.getDeckPage + 1,
        per_page: 6,
        sort_by: sortBy,
      };
      if (this.getDeckPage < 40) {
        return tsClient
          .collections('prezentations')
          .documents()
          .search(searchParameters)
          .then((response) => {
            this.updateIsSearching(false);
            const { hits, found, page } = response;
            this.setDeckPage(page);
            this.totalDecks = found;
            if (hits?.length) {
              if (hits?.length < 6) {
                this.setIsLoadMore(false);
              }
              this.page++;
              return hits.map((tkt) => ({
                ...tkt?.document,
                sections: JSON.parse(tkt?.document?.sections),
              }));
            }
            this.setIsLoadMore(false);
            return [];
          })
          .catch(() => {
            this.updateIsSearching(false);
          });
      }
      this.setIsLoadMore(false);
      return [];
    },
    async onAdvancedFilerChangeSlides() {
      EventBus.$emit('CLEAR_BPE_SLIDE_LIBRARY_SEARCH');
      this.searchModel = '';
      this.updateSortByValue('Recommended');
      this.setSelectedTrendingIdeas(['All']);
      const activeTypes = [];
      this.advancedItems.forEach((item) => {
        const activeType = item?.items.find((typeItem) => typeItem.active);
        if (activeType) {
          activeTypes.push(activeType.title);
        }
      });
      if (activeTypes.length === 0) {
        this.getSlidesList();
        return;
      }

      let queryType = '';
      if (
        activeTypes.includes('My Downloads') &&
        activeTypes.includes('My Favorites')
      ) {
        queryType = 'all';
      } else if (
        activeTypes.length === 1 &&
        activeTypes.includes('My Downloads')
      ) {
        queryType = 'downloaded';
      } else if (
        activeTypes.length === 1 &&
        activeTypes.includes('My Favorites')
      ) {
        queryType = 'favorites';
      }

      this.handleGetCompanyTheme();

      handleKnowSearchBySort({
        query_type: queryType,
        theme_id: this.companyTheme, // this.currentTheme.code,
      })
        .then(async (res) => {
          const list = res.data.payloads || [];
          this.slidesList = [...list];
          this.setSlidesList(this.slidesList);
          this.setSuccessfulSlideDataRetrieve(list, false);
          this.slidesListLoaded = this.slidesList;
          this.seeMoreSlides = false;
          this.updateIsSearching(false);
        })
        .catch((err) => console.log(err));
    },
    async makesApiCallsForSlides() {
      this.updateIsSearching(true);

      if (
        this.companyData &&
        this.sourceList[0].name !== this.companyData.name
      ) {
        this.sourceList.push({
          name: capitalizeFirstLetter(
            this.companyData.displayName
              ? this.companyData.displayName
              : this.companyData.name,
          ),
          key: this.companyData.name,
          checked: true,
          disabled: true,
        });
      }

      // Source displayname reference onject
      const sourceRefObj = {};
      this.sourceList.forEach((e) => {
        sourceRefObj[e.key] = e.name;
      });
      if (this.setSourceDisplayNameRef)
        this.setSourceDisplayNameRef(sourceRefObj);

      try {
        await this.handleGetCompanyTheme();
      } catch (e) {
        console.log(e);
      }

      if (!this.isTrialExpired && this.isKnowEnabled) {
        this.initialData();
      } else {
        this.$router.push('/home', () => {});
      }
    },
    async initialData() {
      if (this.companyTheme === 'generic_booster_pack') {
        this.trendingKeywords = [
          { name: 'All', category: 'All', count: 0 },
          ...defaultIdeasList,
        ];
      }
      if (
        this.getBPESlideLandingSearchText.length <= 0 &&
        !this.knowBackClicked
      ) {
        this.handleSortByChange(this.sortByValue, 'initial-data');
      }
    },
    incrementSearchCount() {
      const userData = {
        performIncrements: {
          knowSearchCount: 1,
        },
      };
      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUserDetails) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUserDetails;
          this.setCurrentUser(userInfo);
        })
        .catch((err) =>
          console.log('error while updating the search count', err),
        );
    },
    async getSlidesList(fromSeeMore, origin) {
      const theme = this.getTheme('hasBPGSlides');
      const savedQueryPayload = {
        andprefs: {
          theme,
          is_know: 'yes',
        },
        category:
          this.knowCategory && this.getPreviousRoute !== 'hybridSearch'
            ? [this.knowCategory]
            : [],
        categories: [],
        choice: {
          data: 4.61,
          visual: 5.97,
        },
        limit: 24,
        morprefs: {
          source: [],
        },
        notprefs: {
          category: [
            'nature',
            'mountain',
            '80s',
            'sports',
            '4th_of_july',
            'thanksgiving',
            'christmas',
            'sea',
            'movie',
            'space',
            'race_car',
            'prezfingerprinttest',
          ],
          source: [],
        },
        onlyids: false,
        orderby: this.getsortByValue(),
        orprefs: [],
        query: this.knowQuery.toLowerCase(),
        skip: 0,
        username: this.currentUser.user.cognitoID,
      };
      if (!fromSeeMore) this.updateIsSearching(true);

      // Push selected categories
      // categories is accepting array
      if (
        !this.selectedTrendingIdeas.includes('All') &&
        this.getPreviousRoute !== 'hybridSearch'
      ) {
        this.trendingKeywords.forEach((searchTerm) => {
          if (this.selectedTrendingIdeas.includes(searchTerm.name)) {
            savedQueryPayload.categories = [`${searchTerm.category}`];
            savedQueryPayload.category = [`${searchTerm.category}`];
          }
        });
      }

      if (origin && origin.type && origin.type === 'searchBox') {
        savedQueryPayload.categories = [...origin.idea];
        savedQueryPayload.category = [...origin.idea];
      }

      // if (this.selectedSource.indexOf('All') === -1) {
      //   console.log('Unreachable code----->');
      //   this.sourceList.forEach((item) => {
      //     if (this.selectedSource.indexOf(item.name) !== -1) {
      //       savedQueryPayload.morprefs.source.push(item.key);
      //     } else if (this.selectedSource.length !== 0) {
      //       savedQueryPayload.notprefs.source.push(item.key);
      //     }
      //   });
      // }
      if (
        this.sortByValue === 'A-Z' ||
        this.sortByValue === 'Z-A' ||
        this.sortByValue === 'Most Popular'
      )
        savedQueryPayload.is_recommender_search = false;
      else savedQueryPayload.is_recommender_search = true;
      if (fromSeeMore) {
        savedQueryPayload.skip = this.slidesList.length;
      } else {
        // Leaving this to log an empty search
        AnalyticsHandler.performSlideSearch(
          this.currentUser,
          savedQueryPayload.query || savedQueryPayload.category,
        );
        AnalyticsHandler.logUserStats(this.currentUser);
      }
      await GraphQLService.requestWithToken(
        graphqlOperation(performSearch, {
          parameters: JSON.stringify(savedQueryPayload),
        }),
      )
        .then((res) => {
          const result = JSON.parse(res.data.performSearch);
          if (result.statusCode === 200) {
            const list = result.body.payloads || [];
            this.setSuccessfulSlideDataRetrieve(list, fromSeeMore);

            if (!fromSeeMore) {
              this.logKnowSlideSearchTerm();
              //   const logPayload = {
              //     searchTermResult: !!list.length,
              //   };
              //   if (this.$route?.query?.query) {
              //     logPayload.type = 'Know';
              //     // to log search when searched from main search
              //     this.logSearchQueryFlag = true;
              //   } else {
              //     logPayload.type = 'Best practice guides Chip';
              //   }
              //   if (savedQueryPayload.query) {
              //     logPayload.searchTerm = savedQueryPayload.query.toLowerCase();
              //   } else {
              //     const catName = savedQueryPayload.categories.map((item) =>
              //       item.replace('_', ' ').toLowerCase(),
              //     );
              //     logPayload.searchTerm = catName.length
              //       ? catName.join(', ')
              //       : 'all';
              //   }
              //   if (
              //     this.logSearchQueryFlag &&
              //     !this.logSearchQueryFlagIsLoading &&
              //     origin?.type !== 'searchBox'
              //   ) {
              //     this.logSearchQueryFlagIsLoading = true;
              //     logSearchQuery(logPayload)
              //       .then()
              //       .catch((err) => console.log(err))
              //       .finally(() => {
              //         this.logSearchQueryFlagIsLoading = false;
              //       });
              //   }
              //   if (
              //     origin?.type === 'searchBox' &&
              //     this.getBPESlideLandingSearchText.length === 0
              //   ) {
              //     logPayload.type = 'Best practice guides local';
              //     logPayload.searchTerm = this.searchModel;
              //     logSearchQuery(logPayload)
              //       .then()
              //       .catch((err) => console.log(err))
              //       .finally(() => {});
              //   }
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.updateIsSearching(false);
          this.isFetchingMore = false;
        });
    },
    logKnowSlideSearchTerm() {
      if (this.logSearchPayload?.type && this.logSearchPayload?.searchTerm) {
        this.logSearchPayload.searchTermResult = true;
        logSearchQuery(this.logSearchPayload)
          .then()
          .catch((err) => console.log(err))
          .finally(() => {
            this.logSearchPayload = {};
          });
      }
    },
    setSuccessfulSlideDataRetrieve(list, fromSeeMore) {
      this.seeMoreSlides = true;
      if (fromSeeMore) {
        this.slidesList = [...this.slidesList, ...list];
      } else {
        this.slidesList = [...list];
      }
      this.setSlidesList(this.slidesList);
      this.slidesListLoaded = this.slidesList;
      if (list.length < this.pageSize) {
        this.seeMoreSlides = false;
      }
      this.getSlidesMetadata(list);
    },
    async getSlidesMetadata(list) {
      if (list.length > 0) {
        const arr = list.map((item) => item.unique_id);
        const params = {
          assetIds: arr,
          getDownload: true,
          getFavourite: true,
          getTrending: true,
          getViewCount: true,
        };
        getKnowSlideMetaData(params)
          .then((slideData) => {
            this.slidesMetadata = { ...this.slidesMetadata, ...slideData.data };
            this.setSlidesMeta(this.slidesMetadata);
            this.assignViews(slideData.data);
          })
          .catch((err) => console.log(err));
      }
    },

    assignViews(viewData) {
      this.slidesList = [...this.slidesList].map((slide) => ({
        ...slide,
        viewCount:
          viewData[slide.unique_id] && viewData[slide.unique_id].viewCount
            ? Number(viewData[slide.unique_id].viewCount)
            : 0,
      }));
    },

    loadMoreSlides() {
      trackKnowEvents.knowSeeMoreResults(this.currentUser, {
        [TD_PLACEMENT]: 'Slides',
      });
      this.isFetchingMore = true;
      this.getSlidesList(true);
    },

    handleTagSelection(tag) {
      EventBus.$emit('CLEAR_BPE_SLIDE_LIBRARY_SEARCH');
      this.logSearchPayload.type = 'Best practice guides chip';
      this.logSearchPayload.searchTerm = tag?.name;
      this.searchModel = '';
      this.updateSortByValue('Recommended');
      this.resetAdvancedFilter();
      // to log search when chip is clicked
      this.logSearchQueryFlag = true;
      EventBus.$emit('CLEAR_SEARCH');
      if (this.$route?.query?.category && this.$route?.query?.query)
        this.$router.push('/home/best-practice-library');
      this.knowCategory = '';
      this.knowQuery = '';
      this.setSelectedTrendingIdeas([tag.name]);
      if (tag?.name !== 'All') {
        const queryPayload = {
          type: 'Best Practice Slides',
          category: tag.category,
          name: tag.name,
        };
        this.updateRecentBPESlideQueries(queryPayload);
      }
      this.updateIsSearching(true);
      // Reset list
      this.trendingAllFilter = {
        ...this.trendingAllFilter,
        ...{
          checked: false,
        },
      };
      this.trendingKeywords = this.trendingKeywords.map((source) => ({
        ...source,
        checked: source.name === tag.name,
        disabled: false,
      }));
      this.trendingKeywordsAll = [
        this.trendingAllFilter,
        ...this.trendingKeywords,
      ];
      handleDebounce(async () => {
        await this.handleSortByChange('Recommended', 'idea-chip');
        trackKnowEvents.trackKnowIdeachipClick(this.currentUser, {
          [TD_QUERY]: tag.name,
          [TD_PLACEMENT]: 'Slides',
          [TD_NORESULTSFOUND]: this.slidesList.length === 0 ? '1' : '0',
        });
      });
    },
    async handleSortByChange(value, origin) {
      if (origin === 'filter') {
        trackKnowEvents.knowSortType(this.currentUser, {
          [TD_SORTTYPE]: this.matomoFilterMap[value],
          [TD_PLACEMENT]: this.viewType,
        });
      }
      if (this.viewType === 'Decks') {
        this.updateSortByValue(value, origin);
        // this.setSortByFilterDecks(value);
        return;
      }
      // if (handleSearch === undefined) {
      //   this.setSelectedTrendingIdeas(['All']);
      //   trackKnowEvents.knowSortType(this.currentUser, {
      //     [TD_SORTTYPE]: this.matomoFilterMap[value],
      //     [TD_PLACEMENT]: 'Slides',
      //   });
      // }
      EventBus.$emit('CLEAR_BPE_SLIDE_LIBRARY_SEARCH');
      if (origin !== 'initial-data') {
        EventBus.$emit('CLEAR_SEARCH');
        if (this.$route?.query?.category && this.$route?.query?.query)
          this.$router.push('/home/best-practice-library');
      }
      if (origin !== 'idea-chip') {
        this.setSelectedTrendingIdeas(['All']);
      }
      this.searchModel = '';
      this.resetAdvancedFilter();
      this.updateIsSearching(true);
      if (value === 'Recommended') {
        await this.getSlidesList();
        if (origin === 'idea-chip') {
          const searchTermList = [];
          if (!this.selectedTrendingIdeas.includes('All')) {
            this.trendingKeywords.forEach((searchTerm) => {
              if (this.selectedTrendingIdeas.includes(searchTerm.name)) {
                searchTermList.push(
                  updateKnowSearchTerms({
                    resultShown: true,
                    ...searchTerm,
                    incrementCount: 1,
                  }),
                );
              }
            });
            await Promise.all(searchTermList);
          }
        }
      } else if (
        value === 'A-Z' ||
        value === 'Z-A' ||
        value === 'Most Popular'
      ) {
        this.knowQuery = '';
        this.knowCategory = '';
        this.getSlidesList(false);
      }
      this.getSearchTerms();
    },

    async getSearchTerms() {
      await getKnowUserSearchTerms().then((searchData) => {
        const list = [...searchData.data, ...defaultIdeasList];
        const uniqueList = uniqBy(list, (e) => e.category);
        const termsDesc = orderBy(uniqueList, ['count'], ['desc']);
        const termsDescFiltered = termsDesc.filter(
          (word) => !this.blacklistCategory.includes(word.category),
        );
        this.trendingKeywords = termsDescFiltered.slice(0, 10);
        this.trendingKeywords = this.trendingKeywords.map((source) => ({
          ...source,
          name: source.searchTerm,
          // If query is available keep the items accordingly
          checked:
            this.selectedTrendingIdeas[0] === 'All'
              ? true
              : this.selectedTrendingIdeas.includes(source.searchTerm),
          disabled: this.selectedTrendingIdeas[0] === 'All',
        }));
        // Deselect the all if search term is available
        this.trendingAllFilter.checked =
          this.selectedTrendingIdeas[0] === 'All';
        this.trendingKeywordsAll = [
          this.trendingAllFilter,
          ...this.trendingKeywords,
        ];
      });
    },

    handleQuickSelection(action) {
      EventBus.$emit('MINIMIZE_LEFTNAV');
      if (action && (this.toolType !== action || !this.toolType)) {
        this.toolType = action;
        this.showQuickSelection = true;
      } else if (this.toolType === action || !action) {
        this.toolType = null;
        this.showQuickSelection = false;
      }
    },
    handleScrollToOption(option) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (option === 'search') {
        const searchInput = document.getElementById('bpl-search');
        if (window.scrollY === 0) {
          searchInput.focus();
        } else {
          setTimeout(() => {
            searchInput.focus();
          }, 1000);
        }
      } else if (this.toolType !== 'filter') {
        this.handleQuickSelection(option);
      }
    },
    async getRecentBPESlideSearches() {
      try {
        const userInfo = await usersApi.methods.getUserInfo(
          this.currentUser.email,
        );
        if (userInfo?.user?.recentBPESlideSearches.length > 0)
          this.setBPESlideRecentQueriesOnMount(
            userInfo?.user?.recentBPESlideSearches,
          );
      } catch (err) {
        console.log(err);
      }
    },
    handleSearchWithQuery() {
      if (this.knowQuery.length > 1) {
        const ideaFound = [...this.knowCategories].filter((item) =>
          item
            .toLowerCase()
            .replace('_', ' ')
            .includes(this.knowQuery.toLowerCase()),
        );
        if (ideaFound && ideaFound.length > 0) {
          const foundIdea = this.trendingKeywordsAll.find(
            (kw) => kw.name.toLowerCase() === this.knowQuery.toLowerCase(),
          )
            ? this.knowQuery
            : 'All';
          this.setSelectedTrendingIdeas([foundIdea]);
          // this.knowQuery = foundIdea;
        } else {
          this.setSelectedTrendingIdeas(['All']);
        }

        trackKnowEvents.knowSlideSearch(this.currentUser, {
          [TD_QUERY]: this.knowQuery,
          [TD_NORESULTSFOUND]: ideaFound && ideaFound.length > 0 ? '1' : '0',
          [TD_COMMON_COLUMN_NAME]: 'Type of search',
          [TD_COMMON_COLUMN_VALUE]: this.searchOrigin,
        });
        this.updateSortByValue('Recommended');
        this.resetAdvancedFilter();
        this.getSlidesList(false);
      }
    },
  },
  async mounted() {
    this.tsClient = await getTypeSenseAPIKey();
    EventBus.$on('CLEAR_BPE_LIBRARY_SEARCH', () => {
      this.searchModel = '';
    });
    if (this.getBPEDeckLandingSearchText.length > 0) {
      this.searchModel = this.getBPEDeckLandingSearchText;
    }
    // BPE Slides search handling from Local search
    EventBus.$on('DO_BPE_SEARCH', (payload) => {
      this.logSearchPayload.type = 'Best practice guides local';
      this.logSearchPayload.searchTerm = payload?.query;
      this.searchOrigin = payload.origin;
      this.knowQuery = payload?.query;
      this.knowCategory = payload?.category;
      this.handleSearchWithQuery();
      this.incrementSearchCount();
    });
    // BPE Slides search handling from Search page
    if (this.getBPESlideLandingSearchText.length > 0 && !this.knowBackClicked) {
      this.knowQuery = this.getBPESlideLandingSearchText;
      await this.makesApiCallsForSlides();
      await this.getSearchTerms();
      this.handleSearchWithQuery();
    }
    this.getRecentBPESlideSearches();

    // Add Funtion filters for BPE Deck
    // if (this.isFilterByBPEFunctionEnabled)
    //   await this.getAdvanceFilterFuntions();
    // else this.setFingerPrintAdditionalDetailsBPEDeckOnload();

    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    this.setSlideViewOrigin('');
    EventBus.$on('HANDLE_KNOW_SORTING', this.handleSortByChange);
    EventBus.$on('KNOW_RESET_ADVANCED_FILTERS', this.resetAdvancedFilter);
    EventBus.$on('KNOW_ADVANCE_FILTER_CHANGE', this.onAdvancedFilerChange);
    // if there is a snapshot already exists assigning snapshot values on mount
    if (this.knowBackClicked && Object.keys(this.getBPLSnapshot).length) {
      this.assignKnowSnapshotToData();
      setTimeout(() => {
        this.saveBPLStateSnapshot({});
        this.setKnowBackClicked(false);
      }, 500);
    }
  },
  beforeDestroy() {
    if (this.$route.name !== 'know.detail') this.setBPEviewType('Decks');
    this.resetKnow();
    this.setDeckPage(0);
    this.setIsLoadMore(true);
    this.setBPESlideLocalSearchText('');
    EventBus.$off('DO_KNOW_SEARCH');
    EventBus.$off('HANDLE_KNOW_SORTING', this.handleSortByChange);
    EventBus.$off('KNOW_RESET_ADVANCED_FILTERS', this.resetAdvancedFilter);
    EventBus.$off('KNOW_ADVANCE_FILTER_CHANGE', this.onAdvancedFilerChange);
    EventBus.$off('CLEAR_BPE_LIBRARY_SEARCH');
    EventBus.$off('GOTO_BPE_DECKS');
    EventBus.$off('DO_BPE_SEARCH');
  },
};
</script>

<style lang="scss" scoped>
.know-wrapper {
  overflow-y: scroll;
  display: flex;
}
.quick-panel-active {
  width: calc(100% - 550px) !important;
}
.quick-panel {
  width: 550px;
}
.know-container {
  width: 100%;
  height: 100%;
  padding: 20px 30px;

  .selected-type {
    font-size: 14px;
  }

  ::v-deep .v-slide-group__prev {
    justify-content: left !important;
    align-items: left !important;
    min-width: 40px !important;
  }
  ::v-deep .v-slide-group__next {
    justify-content: right !important;
    align-items: right !important;
    min-width: 40px !important;
  }

  .v-chip {
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    margin-right: 18px !important;
    padding: 0 25px !important;
    background: white !important;
    color: #21a7e0 !important;
    border: 1px solid #21a7e0 !important;

    &.active {
      background: #21a7e0 !important;
      color: white !important;
    }
  }

  .label {
    white-space: nowrap;
  }

  .d-flex {
    align-items: center;

    &.pull-right {
      justify-content: flex-end;
    }

    .v-input {
      padding: 0 0 0 5px;
    }
    ::v-deep .v-input__slot {
      margin: 0;
    }
    ::v-deep .v-select__selection {
      margin: 0;
      font-size: 14px;
    }
    ::v-deep .v-select__selections input {
      padding: 0;
      font-size: 14px;
    }
    ::v-deep .v-input__append-inner {
      margin-top: 0px;
    }
    ::v-deep .v-text-field__details {
      display: none;
    }
    ::v-deep .v-text-field {
      margin-top: 3px;
    }
  }

  .btn {
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
    margin-left: 20px;
  }

  .no-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    img {
      margin: 40px 0;
    }

    p {
      font-size: 20px;
    }
  }

  .pageFilters {
    display: flex;
    margin: 24px 0px 24px 0px;
    padding: 0px 0px 0px 8px;
    justify-content: space-between;
    .leftFilters {
      display: flex;
      .searchBox {
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 24px;
        box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
        display: flex;
        font-size: 14px;
        height: 40px !important;
        padding: 2px 14px 8px 20px;
        z-index: 3;
        max-width: 380px;
        width: 380px;
        margin: 0px 42px 0px 0px;
        ::v-deep .v-input {
          ::placeholder {
            color: #757575 !important;
          }
          margin: 0 !important;
          padding: 0 !important;
          font-size: 14px;
          color: #464a4c;
          .v-input__control {
            .v-input__slot {
              .v-input__append-inner {
                margin: 0;
                padding: 4px 0px 0px 4px;
              }
              .v-text-field__slot {
                input {
                  caret-color: black !important;
                }
              }
              &::before,
              &::after {
                border: none !important;
              }
            }
          }
        }
        ::v-deep .v-input__slot::before {
          border-style: none !important;
          display: none;
        }
        ::v-deep .v-input__icon {
          margin-right: -8px;
        }
      }
      .sortByFilter {
        width: 40%;
        max-width: 40%;
        display: flex;
        .sortByContent {
          display: flex;
          .label {
            padding: 10px 12px 0px 0px;
          }
          ::v-deep .v-input {
            padding: 4px 0;
            margin: 0;
            width: 200px;
            .v-input__control {
              .v-input__slot {
                margin: 0;
              }
              .v-text-field__details {
                display: none;
              }
            }
          }
        }
      }
    }
    .rightFilters {
      display: flex;
      align-items: center;
      .viewRadios {
        display: flex;
        justify-content: flex-end;

        ::v-deep .v-input {
          margin: 0;
          padding: 0;
          .v-input__control {
            .v-input__slot {
              margin: 0;
            }
            .v-messages {
              display: none;
            }
            .v-input--radio-group--row {
              gap: 0.75px;
            }
          }

          .v-label {
            color: #21a7e0;
            font-family: Lato;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.01em;
            text-align: center;
          }
        }
      }
      .quick-action-cta {
        display: flex;
        gap: 0.75rem;
      }

      .filter-btn {
        color: #21a7e0;
        border-radius: 10px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.01em;
        text-align: center;
        height: 100%;
        min-width: 100px;
        padding: 0.5rem;
        display: flex;
        gap: 0.5rem;
        justify-content: space-between;
        align-items: center;
      }

      .filter-btn-active {
        background-color: #e1f5fb;
      }
      .advFilterBtn {
        padding: 8px 8px 0px 0px;
      }
    }
    .advancedInActive {
      cursor: pointer;
    }
    .advancedActive {
      color: #21a7e0 !important;
    }
  }

  .lists {
    display: flex;
    gap: 10px;
    .slideslist {
      .spinning-loading-wrapper {
        width: inherit !important;
        position: relative !important;
      }
    }
    .advanced-filter-wrapper {
      box-shadow: -10px 0px 16px -6px rgba(0, 0, 0, 0.15);
      width: 360px;
      z-index: 4;
      height: calc(100vh - 70px);
      top: 70px;
      bottom: 0px;
      position: sticky;
      transition: width 1s;
      width: 15%;
      ::v-deep .exp-filter-header {
        display: flex;
        align-items: center;
        gap: 5px;
        .filter-icon {
          width: 24px;
          height: 24px;
        }
        .badge-container {
          position: absolute;
          z-index: 4;
          left: 130px;
          bottom: 25px;
        }
        .badge-container-author {
          position: absolute;
          z-index: 4;
          left: 90px;
          bottom: 25px;
        }
      }
      ::v-deep .v-expansion-panels {
        overflow: auto;
        .v-expansion-panel {
          flex: none;
          width: 100%;
          &::before {
            box-shadow: none;
          }
          &::after {
            opacity: 0 !important;
          }
        }
        .v-expansion-panel-header {
          padding: 0 16px;
          min-height: 47px !important;
        }
        .v-expansion-panel-content__wrap {
          padding: 0;
        }
        .v-list-item {
          min-height: 30px !important;
          padding: 0;
          padding-left: 60px;
          .v-list-item__action {
            margin: 0px 7px 0px 0px;
          }
          .v-list-item__content {
            padding: 8px 0px;
            .v-list-item__title {
              font-size: 16px;
              line-height: 18px !important;
            }
          }
        }
      }
      .filter-header {
        padding: 5px 10px 5px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          display: inline-block;
          font-size: 17px;
        }
      }
      .clear-text {
        color: #21a7e0 !important;
        cursor: pointer;
      }
      .resetFilters {
        display: flex;
        padding: 15px 15px 15px 15px;
        justify-content: space-between;
        .resetText {
          color: #21a7e0 !important;
          cursor: pointer;
        }
      }
      .disabledResetFilters {
        display: flex;
        padding: 15px 0px 15px 15px;
        padding: 15px 15px 15px 15px;
        justify-content: space-between;
        .v-icon,
        .resetText {
          color: grey;
          margin: 0px 0px 0px 5px;
          cursor: default;
        }
      }
    }
  }
}
.select-all-item {
  .select-all-checkbox {
    ::v-deep .v-label {
      margin-left: 30px !important;
    }
  }
}

.v-menu__content {
  .v-list {
    .v-list-item {
      .v-list-item__title {
        .v-input {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
</style>
